import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TEsiaParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import { TChips } from './CreateProvider';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalWithAction } from './modal/ModalWithAction';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';

export type EditEsiaProviderInputs = {
  name: string;
  description: string;
  external_client_id: string;
  scopes: string;
  avatar: File | null;
  path_to_avatar: string;
  provider_id: string;
  auto_registration: boolean;
  is_public: boolean;
  mapping: string;
  issuer: string;
  sign_endpoint: string;
  verify_endpoint: string;
  authorization_endpoint: string;
  token_endpoint: string;
  userinfo_endpoint: string;
};

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  authorization_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  token_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  userinfo_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  sign_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  verify_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auto_registration: yup.boolean(),
  is_public: yup.boolean(),
  mapping: yup.string().max(2000, 'Mapping не может превышать 2000 символов'),
});

export const EditEsiaProvider: FC<
  TEditProvider<
    TMiscProvider & {
      params: TEsiaParams;
      provider_id: string;
      avatar?: File | null;
    }
  >
> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditEsiaProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [chips, setChips] = useState<TChips[]>([]);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
      setChips([]);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<
      TMiscProvider & {
        params: TEsiaParams;
        scopes: string;
        provider_id: string;
        avatar?: File | null;
      }
    >,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              params: TEsiaParams;
              scopes: string;
              provider_id: string;
              avatar?: File | null;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, type, params, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.scopes;

      if (typeof type === 'string' && type in MiscProviderType) {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<
                TMiscProvider,
                | 'avatar'
                | 'scopes'
                | 'type'
                | 'params'
                | 'id'
                | 'client_id'
                | 'is_active'
                | 'auth_without_email'
                | 'password_required'
                | 'provider_colors'
                | 'provider_title'
                | 'show_provider_avatar'
                | 'disable_password_reset'
              >
            >
          ).forEach((field) => {
            if (field === 'auto_registration' || field === 'is_public') {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (params) {
          (Object.keys(params as TEsiaParams) as Array<keyof TEsiaParams>).forEach((field) => {
            if (field === 'scopes' && params?.[field]) {
              setChips(
                params?.[field]
                  ?.split(' ')
                  .map((scope, index) => ({ value: scope, key: String(index), changing: false })) ||
                  [],
              );
              return;
            }
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
      } else {
        setClipboardModalOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditEsiaProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              scopes: string;
              external_client_id: string;
              authorization_endpoint: string;
              token_endpoint: string;
              sign_endpoint: string;
              verify_endpoint: string;
              userinfo_endpoint: string;
              issuer: string;
              mapping: string;
              provider_id: string;
            }
          >,
          field,
        ) => {
          if (field === 'scopes') return acc;
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (field === 'auto_registration' || field === 'is_public') {
            acc[field] = data[field];
            return acc;
          }
          acc[field] = data[field];
          return acc;
        },
        {},
      );

      payload.scopes = chips.map((chip) => chip.value).join(' ');
      if (!isObjectEmpty(payload))
        updateProvider({
          body: {
            ...payload,
            provider_id: providerToEdit.id,
            type: providerToEdit.type,
          },
          client_id: clientId,
        });
    }
  };
  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать способ входа
            </CustomTypography>
            <IconWithTooltip
              iconType="pasteSettings"
              action={() => {
                setFields();
              }}
            />
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Идентификатор ресурса (client_id)
            </CustomTypography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Уникальный идентификатор подключаемого ресурса
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Адрес получения подписи запроса (sign_endpoint)
            </CustomTypography>
            <TextField
              {...register('sign_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.sign_endpoint) clearErrors('sign_endpoint');
                },
              })}
              className="custom"
              error={!!errors.sign_endpoint}
              helperText={errors.sign_endpoint ? errors.sign_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Ресурс, который подписывает запрос
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Адрес проверки подписи запроса (verify_endpoint)
            </CustomTypography>
            <TextField
              {...register('verify_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.verify_endpoint) clearErrors('verify_endpoint');
                },
              })}
              className="custom"
              error={!!errors.verify_endpoint}
              helperText={errors.verify_endpoint ? errors.verify_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Ресурс, который проверяет подпись ответа от ЕСИА
            </CustomTypography>
            <ProviderFooter type={providerToEdit?.type as MiscProviderType} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для провайдера."
      />
    </Drawer>
  );
};
